.contact-flydown-container {
  background: rgba(10,10,10, .35);
  opacity: .5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display:none;
  z-index: 999;

  #contact-flydown {
    background-color: $white;
    height: auto;
    left: 0;
    padding: 5rem;
    position: fixed;
    top: -800px;
    transition: top .8s ease-in-out;
    width: 100%;

    .close {
      font-family: $body;
      color: $red;
      font-size: 1.2em;
      font-weight: 700;
      position: absolute;
      right: 1.5rem;
      top: 1.125rem;
      text-decoration: none;
      text-transform: uppercase;

        .closeLine {
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            background: $red;
            margin-right: 6px;
            position: relative;
            top: -1px;
        }
    }
    .contactForm {
      padding: 2rem 0 0 3rem;
    }
    .spacer {
      bottom: 0;
      left: 0;
      position: absolute;
    }
      a {
          text-decoration: none;
          text-transform: none;
          color: $black;
          @extend .transition-color;

          &:hover {
              color: $iron;
          }
      }
  }
  &.open {
    display: block;
    opacity: 1;
    #contact-flydown {

        top: 0;

    }
  }

  .departmentCard {
    background: #e1e2e2;
    border-radius: 3px;
    text-align: center;
    padding: 60px 30px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .faIcon {
      font-size: 35px;
      display: block;
      color: #b9b9b9;
      margin-bottom: 22px;
      position: relative;
      z-index: 11;
    }

    .headline {
      font-size: 18px;
      color: #050f50;
      font-weight: 600;
      display: block;
      line-height: 1.3em;
      position: relative;
      z-index: 10;
    }

    &.active, &:hover {
      background: transparent linear-gradient(270deg,#ffc000,#ff2900) 0 0 no-repeat padding-box;
      border-radius: 3px;

      .headline, .faIcon {
        color: #fff;
      }
    }
  }
}
