.navigation {
    background: transparent;
    @extend .unformatList;
    
    .uk-offcanvas-bar {
        background: $black;  
        bottom: unset;
        height: auto;
        min-height: 550px;
        width: 70%;
        right: -70%;
        @include touch {
            width: 100%;
            right: -100%;
            min-height: auto;
            bottom: 0;
        }
    }
    
    .iconLogo {
        @include handheld {
            width: 85px;
        }
    }
    .closeNav {
        font-family: $body;
        font-size: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        /*&::before {
            content: '■'; 
            color: $red;
        }*/
        .closeLine {
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            background: $red;
            margin-right: 6px;
            position: relative;
            top: -1px;
        }
        &:hover {
            color: $red;
        }
    }
    .navContent {
        @include tablet {
            margin-top: 80px;
        }
        @include mobile {
            margin-top: 60px;
        }
        @include small-mobile {
            margin-top: 40px;
        }
        .media {
            img {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
        .secondary {
            padding: 20px 0;
        }
        .socialMedia {
            @include screen {
                float: left;
            }
            @include small-desktop {
                float: none;
                margin-bottom: 15px;
            }
        }
        .legal {
            @include screen {
                float: right;
                position: relative;
                top: 5px;
            }
            @include small-desktop {
                float: none;
            }
        }
    }
    nav {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 80%;
        // First level
        .navItems {
            @extend .transition-all;
            li {
                a {
                    color: $white;
                    border-bottom: solid 1px $iron;
                    display: block;
                    padding: 15px 0;
                    font-size: 1.2em;
                    text-transform: uppercase;
                    position: relative;
                    
                    span {
                        color: $white;
                        margin: 0;
                        @extend .transition-color;
                    }
                    .svg-inline--fa {
                        //display: inline-block;
                        color: $red;
                        //float: right;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        @extend .transition-color;
                    }
                    &:hover {
                        color: $metal;
                        
                        span {
                            color: $metal;
                        }
                        .svg-inline--fa {
                            color: $metal;
                        }
                    }
                    
                    &.back {
                        .backText {
                            position: relative;
                            left: 30px;
                        }
                        .svg-inline--fa {
                            //float: none;
                            left: 0;
                        }
                        
                        &:hover {
                            color: $red;
                            
                            span {
                                color: $red;
                            }
                            .svg-inline--fa {
                                color: $red;
                            }
                        }
                    }
                }
                .navItems {
                    display: none;
                }
                .subGroup {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin-left: -700px;
                    z-index: -1;
                    transform: translateX(-660px);
                    &.active {
                        opacity: 1;
                        margin-left: 0;
                        z-index: 1;
                    }
                }
            }
            &.active {
                transform: translateX(660px);
            }
        }
    }
    .pattern {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 38px;
      }
}