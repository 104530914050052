.timelineBlock {
    background: $black;
    padding: 80px 0;
    
    .intro {
        text-align: center;
        
        h2 {
            color: $white;
        }
        p {
            color: $white;
        }
    }
    .timeline {
        width: 70%;
        margin: 80px auto 60px auto;
        padding: 0;
        
        @include small-desktop {
            width: 85%;
        }
        @include touch {
            width: 100%;
        }
        
        .milestone {
            display: block;
            position: relative;
            width: 100%;
            
            &.left {
                .data {
                    float: left;
                    border-right: 2px solid $iron;
                    padding-right: 45px;
                    text-align: right;
                    position: relative;
                    left: 1px;
                    align-items: flex-start;
                    
                    @include handheld {
                        float: right;
                        border-left: 2px solid $iron;
                        padding-left: 60px;
                        padding-right: 0;
                        border-right: none;
                        text-align: left;
                        padding-left: 30px;
                    }
                    
                    .year {
                        float: left;
                        
                        &:after {
                            content: '';
                            width: 75%;
                            height: 100%;
                            position: absolute;
                            background: inherit;
                            z-index: -1;
                            top: 0;
                            right: 0;
                            transform-origin: left top;
                            transform: skewX(25deg);
                        }
                    }
                    .arrow {
                        right: -7px;
                        
                        @include handheld {
                            right: unset;
                            left: -5px;
                        }
                    }
                }
            }
            &.right {
                .data {
                    float: right;
                    border-left: 2px solid $iron;
                    padding-left: 45px;
                    position: relative;
                    right: 1px;
                    align-items: flex-end;
                    
                    @include handheld {
                        padding-left: 30px;
                        align-items: flex-start;
                    }
                    
                    .year {
                        float: right;
                        
                        &:after {
                            content: '';
                            width: 75%;
                            height: 100%;
                            position: absolute;
                            background: inherit;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            transform-origin: left top;
                            transform: skewX(-25deg);
                        }
                        
                        @include handheld {
                            float: left;
                            
                            &:after {
                                content: '';
                                right: 0;
                                left: unset;
                                transform: skewX(25deg);
                            }
                        }
                    }
                    .arrow {
                        left: -7px;
                        
                        @include handheld {
                            left: -5px;
                        }
                    }
                }
            }
            
            .marker {
                width: 20px;
                height: 20px;
                border-radius: $round;
                background: $iron;
                border: 4px solid $black;
                margin: 0 auto;
                position: relative;
                z-index: 12;
                top: 75px;
                position: absolute;
                left: 0;
                right: 0;
                
                @include handheld {
                    top: 80px;
                    left: -8px;
                    margin: 0;
                }
            }
            .data {
                display: flex;
                flex-direction: column;
                width: 50%;
                padding-top: 15px;
                padding-bottom: 15px;
                position: relative;
                z-index: 11;
                
                @include handheld {
                    float: none;
                    width: 100%;
                    right: 0!important;
                    left: 0!important;
                }
                
                .year {
                    font-family: $body;
                    font-size: 16px;
                    font-weight: 700;
                    background: $ltGrey;
                    padding: 6px;
                    min-width: 65px;
                    max-width: 130px;
                    position: relative;
                    text-align: center;
                    z-index: 14;
                }
                .content {
                    background: $white;
                    padding: 20px;
                    width: 100%;
                    position: relative;
                    
                    h3 {
                        font-family: $body;
                        font-size: 18px;
                        font-weight: 800;
                        margin-bottom: 8px;
                    }
                    p {
                        font-size: 17px;
                        margin: 0;
                    }
                    .arrow {
                        width: 14px;
                        height: 14px;
                        transform: rotate(45deg);
                        background: white;
                        position: absolute;
                        top: 28px;
                        
                        @include handheld {
                            width: 10px;
                            height: 10px;
                            top: 33px;
                        }
                    }
                }
            }
        }
    }
}