.banner {
    width: 100%;
    position: relative;
    background: $black;
    overflow: hidden;
    
    .bgImg {
        @extend .backgroundSettings;
        z-index: 1;
    }
    .container {
        position: relative;
        z-index: 5;
    }
    
    &.quote {
        display: flex;
        align-items: flex-end;
        justify-content: left;
        min-height: 575px;
        padding: 120px 0 0 0;

        @include handheld {
            align-items: center;
            justify-content: center;
            padding: 80px 0;
            max-height: auto;
        }
        
        .quoteBox {
            width: 40%;
            max-width: 550px;
            background: rgba(37, 47, 56, 0.8);
            border-left: 6px solid $red;
            padding: 40px;
            position: relative;
            left: 6%;
            z-index: 5;
            
            @include tablet {
                width: 60%;
            }
            @include mobile {
                width: 80%;
                padding: 30px;
                left: 0;
            }
            @include small-mobile {
                width: 85%;
                padding: 25px;
                left: 0;
            }
            
            .icon {
                font-family: $primary;
                font-weight: 700;
                font-size: 50px;
                color: $red;
                line-height: 0;
                margin: 0;
                position: relative;
                top: 8px;
            }
            blockquote {
                font-size: 22px;
                font-family: $body;
                font-weight: 400;
                font-style: normal;
                line-height: 1.4em;
                color: $white;
                margin: 10px 0;

                p {
                    @extend blockquote;
                }
            }
            .credit {
                font-family: $primary;
                font-weight: 700;
                font-size: 20px;
                color: $white;
                margin: 20px 0 0 0;

                span {
                    color: $red;
                }
            }
        }
    }
    
    &.stats {
        text-align: center;
        padding: 120px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include touch {
            padding: 90px 0 50px 0;
        }
        
        .headlines {
            @include screen {
                width: 75%;
                margin: 0 auto;
            }
            @include small-desktop {
                width: 85%;
                margin: 0 auto;
            }
            h2 {
                color: $white;
                font-size: 40px;
                text-transform: uppercase;
                margin: 0;

                @include touch {
                     font-size: 34px;
                }
            }
            h3 {
                font-family: $body;
                color: $white;
                font-size: 20px;
                font-weight: 800;
                margin: 30px 0 0 0;
            }
        }
        ul {
            display: flex;
            justify-content: space-between;
            align-content: center;
            max-width: 75%;
            margin: 60px auto 0 auto;
            padding: 0;
            
            @include touch {
                display: block;
                max-width: 90%;
            }
            
            li {
                margin: 0;
                padding: 0 20px;
                
                @include touch {
                    margin: 40px 0;
                    padding: 0;
                }
                
                img {
                    width: 96px;
                    display: block;
                    margin: 0 auto 30px auto;
                }
                h3 {
                    color: $yellow;
                    margin: 0;
                    font-size: 80px;
                    
                    @include touch {
                        font-size: 64px;
                    }
                }
                h4 {
                    color: $white;
                    margin: 0;
                    font-family: $body;
                    font-size: 22px;
                    
                    @include touch {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    &.network {
        padding: 120px 0;
        
        .columns {
            align-items: center;
        }
        h2 {
            color: $white;
        }
        h3 {
            color: $white;
            font-size: 23px;
            font-weight: 400;
            text-transform: uppercase;
            text-align: right;
            margin: 10px 0 0 0;
            
            @include touch {
                text-align: left;
                font-size: 18px;
            }
        }
        .button {
            margin-top: 20px;
        }
        .networkLogos {
            float: right;
            padding: 0;
            
            @include touch {
                float: none;
                text-align: left;
                margin: 40px auto 0 auto;
            }
            li {
                display: inline-block;
                margin-left: 25px;
                
                @include touch {
                    margin-left: 0;
                    margin-right: 25px;
                }
            }
            .logoBox {
                width: 120px;
                height: 120px;
                background-color: $white;
                border-radius: $round;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                
                @include tablet {
                    width: 100px;
                    height: 100px;
                }
                @include handheld {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
}