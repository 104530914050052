#videoLibrary {
    background: $black;
    
    h2 {
        color: $metal;
    }
    h4 {
        color: $white;
        //margin-top: -8px;
        margin-bottom: 30px;
    }
    .primary {
        margin-bottom: 100px;
        
        @include small-desktop {
            margin-bottom: 80px;
        }
        @include touch {
            margin-bottom: 60px;
        }
    }
}