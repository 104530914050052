#error {
    background-color: $black;
    height: 60vh;
    min-height: 600px;
    padding-top: 30vh;
    text-align: center;
    h2 {
        color: $white;
        &:before {
            content: '';
            display: block;
            margin: 0 auto 20px auto;
            height: 5px;
            background-color: $red;
            width: 50px;
        }
    }
    p {
        color: $white;
    }
}