.svGroup { 
    &.alt {
        padding: 0;

        .textBlock {
            padding-top: 50px;
            padding-bottom: 50px;
            position: relative;
            z-index: 5;
        }
        .container {
            &.is-widescreen {
                padding-left: 0px!important;
                padding-right: 0px!important;

                @include desktop {
                    min-width: 100%;
                }
                @include small-desktop {
                    min-width: 100%;
                }
            }
        }
        .splitView {
            &.contentRight {
                flex-direction: row;
                    
                @include touch {
                    flex-direction: row-reverse;
                }
                .svContent {
                    @include screen {
                        padding-right: 32px;
                        padding-left: 0;
                    }
                    @include touch {
                        padding-right: 32px;
                        padding-left: 32px;
                    }
                }
            }
            &.contentLeft {
                flex-direction: row-reverse;
                .svContent {
                    @include screen {
                        padding-left: 32px;
                        padding-right: 0;
                    }
                    @include touch {
                        padding-right: 32px;
                        padding-left: 32px;
                    }
                }
            }
            &.overlapSingle {
                overflow: visible;
                position: relative;
                display: flex;
                justify-content: space-between;

                @include touch {
                    display: block;
                }
                
                .svContent {
                    width: 45%;

                     @include touch {
                        width: 100%;
                    }
                }
                .svMedia {
                    position: relative;
                    padding: 0;
                    width: 45%;

                    @include touch {
                        width: 100%;
                    }
                    
                    .topLayer {
                        img {
                            max-width: unset;
                            height: 460px;
                            position: absolute;
                            bottom: 0;

                            @include tablet {
                                width: 85%;
                                margin: 0 auto -30px auto;
                                display: block;
                                height: auto;
                                position: relative;
                                top: -30px;
                            }
                            @include handheld {
                                width: 100%;
                                margin: 0 auto -25px auto;
                                display: block;
                                height: auto;
                                position: relative;
                                top: -25px;
                            }
                        }
                    }   
                }
                &.contentRight {
                    .topLayer {
                        img {
                            @include small-desktop {
                                left: -130px;
                            }
                            @media (min-width: 1180px) and (max-width: 1280px) {
                                left: -70px;
                            }
                        }
                        &.wideImage {
                            img {
                                @include large-desktop {
                                    left: -250px;
                                }
                                @include desktop {
                                    left: -270px;
                                }
                                @include small-desktop {
                                    left: -390px;
                                }
                                @media (min-width: 1180px) and (max-width: 1280px) {
                                    left: -285px;
                                }
                            }
                        }
                    } 
                }
                &.contentLeft {
                    .topLayer {
                        img {
                            @include small-desktop {
                                right: -130px;
                            }
                            @media (min-width: 1180px) and (max-width: 1280px) {
                                right: -70px;
                            }
                        }
                        &.wideImage {
                            img {
                                @include large-desktop {
                                    right: -250px;
                                }
                                @include desktop {
                                    right: -270px;
                                }
                                @include small-desktop {
                                    right: -390px;
                                }
                                @media (min-width: 1180px) and (max-width: 1280px) {
                                    right: -285px;
                                }
                            }
                        }
                    } 
                }
            }




            &.overlapDouble {
                overflow: visible;
                position: relative;
                display: flex;
                justify-content: space-between;

                @include touch {
                    display: block;
                }

                .svContent {
                    width: 45%;

                    @include touch {
                        width: 100%;
                    }
                }
                .svMedia {
                    position: relative;
                    padding: 0;
                    width: 45%;

                    @include touch {
                        width: 100%;
                    }

                    .backgroundImage {
                        height: 100%;
                        width: 100%;
                        @extend .backgroundSettings;
                        z-index: 1;

                        @include tablet {
                            height: 450px;
                            width: 100%;
                        }
                        @include handheld {
                            height: 320px;
                            width: 100%;
                        }
                    }
                    .topLayer {
                        position: absolute;
                        z-index: 2;
                        bottom: 0;
                        
                        @include tablet {
                            left: 0!important;
                            right: 0!important;
                            margin: 0 auto;
                            display: block;
                            text-align: center;
                        }
                        @include handheld {
                            left: 0!important;
                            right: 0!important;
                            margin: 0 auto;
                            display: block;
                            text-align: center;
                        }

                        img {
                            max-width: unset;
                            height: 460px;

                            @include tablet {
                                height: 470px;
                            }
                            @include handheld {
                                height: 340px;
                            }
                        }
                    }   
                }
                &.contentRight {
                    .topLayer {
                        right: -18%;

                        @include small-desktop {
                            right: -16%;
                        }

                        &.wideImage {
                            right: -16%;

                            @include small-desktop {
                                right: -18%;
                            }
                            @include touch {
                                right: 0;
                            }
                        }
                    }   
                }
                &.contentLeft {
                    .topLayer {
                        left: -18%;

                        @include small-desktop {
                            left: -16%;
                        }

                        &.wideImage {
                            left: -16%;

                            @include small-desktop {
                                left: -18%;
                            }
                            @include touch {
                                left: 0;
                            }
                        }
                    }   
                }
            }
        }
    }
}