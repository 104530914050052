.fade-in {
  animation: fadeIn ease-in 1.5s;
  //will-change: opacity;
    
  @include touch {
    animation: fadeIn ease-in .5s;
  }
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.fastFade {
  animation: fadeIn ease-in 1s;
  //will-change: opacity;
    
  @include touch {
    animation: fadeIn ease-in .5s;
  }
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.slowFade {
    //will-change: opacity;
    &.left {
        animation: fadeSlowL ease-in 2s;
        
        @include touch {
            animation: fadeSlow ease-in 1s;
        }
    }
    &.right {
        animation: fadeSlowR ease-in 2s;
        
        @include touch {
            animation: fadeSlow ease-in 1s;
        }
    }
    &.fade {
        animation: fadeSlow ease-in 2s;
        
        @include touch {
            animation: fadeSlow ease-in 1s;
        }
    }
}
@keyframes fadeSlow {
  0% {
    opacity:0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}
@keyframes fadeSlowL {
  0% {
    opacity:0;
    transform: translateX(-900px)
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity:1;
    transform: translateX(0px)
  }
}
@keyframes fadeSlowR {
  0% {
    opacity:0;
    transform: translateX(900px)
  }
  80% {
      opacity: 0;
    }
  100% {
    opacity:1;
    transform: translateX(0px)
  }
}
.pulse {
    animation: pulse 1.5s infinite;
    //will-change: transform;
}
@keyframes pulse {
    0% {
        transform: scale(0.94);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }
    100% {
        transform: scale(0.94);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
.slide-in {
    animation: slideIn ease 1s;
    //will-change: transform;
    
    @include touch {
        animation: none;
    }
}
@keyframes slideIn {
    0% {
        transform: translateY(-300px)
    }
    100% {
        transform: translateY(0px)
    }
}

.grow {
    animation: grow ease-in 3s;
    
    @include touch {
        animation: none;
    }
}
@keyframes grow {
    0% {
        height: 0;
    }
    80% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}