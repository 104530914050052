input {
    font-family: $body;
    background-color: $white;
    border: 1px solid $metal;
    padding: 8px;
    -webkit-appearance: none;

    &:focus-visible {
        outline-style: none;
        background-color: $ltGrey;
    }
}
select {
    font-family: $body;
    background-color: $white;
    border: 1px solid $metal;
    padding: 9px;
    -webkit-appearance: none;

    &:focus-visible {
        outline-style: none;
        background-color: $ltGrey;
    }
}
textarea {
    @extend input;
    resize: none;
}
label {
    font-family: $body;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 5px 0;
}
.freeform-row .freeform-column .freeform-label.freeform-required:after {
    color: $red;
}
.freeform-row {
    @include handheld {
        display: block!important;
    }
}

//Pagination
.pagination-link {
    font-family: $body;
}
.pagination-next {
    font-family: $body;
}
.pagination-previous {
    font-family: $body;
}
.pagination-link.is-current {
    background-color: $black;
    border-color: $black;
}

//Ajax
.ff-form-errors {
    margin-bottom: 15px;
    p {
        color: $black;
        font-size: 16px!important;
        font-weight: 600;
        border-left: 4px solid $red;
        padding-left: 10px;
        margin: 0;
    }
}
.ff-errors {
    margin: 5px 0 -10px 0!important;
    li {
        color: $red;
        font-size: 13px;
    }
}
.ff-form-success {
    padding: 15px;
    border: 1px solid $metal;
    background: $ltGrey;
    border-radius: 5px;
    margin-bottom: 15px;
    p {
        color: $black;
        font-size: 16px!important;
        font-weight: 600;
        border-left: 4px solid $blue;
        padding-left: 10px;
        margin: 0;
    }
}

//Non-Ajax
.freeform-form-has-errors {
    border-radius: 5px;
    background: #f8d7da;
    border: 1px solid #f5c6cb;
    border-left: 4px solid $red;
    @extend p;
    margin: 0 0 15px 0;
    padding: 15px;
    color: $black;
    font-size: 16px!important;
    font-weight: 600;
}
.errors {
    margin: 5px 0 -10px 0!important;
    li {
        color: $red;
        font-size: 13px;
    }
}
.uk-checkbox {
    background-color: #FFF;
    padding: 0 8px;
    margin: auto;
}
.uk-select {
    border: 1px solid #84939e;
}
