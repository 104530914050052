.modal {
    &.video {
        .modal-content {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            width: 60%;
            
            @include tablet {
                width: 75%;
            }
            @include handheld {
                width: 90%;
            }
        }
        .closeModal {
            position: absolute;
            top: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 0;
            border: none;
            box-shadow: none;
            padding: 10px;
            cursor: pointer;
            @extend .transition-all;
            
            svg {
                color: $white;
                width: 30px;
                padding: 0 8px;
                @extend .transition-all;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.8);
                
                svg {
                    opacity: .5;
                }
            }
        }
    }
}