.socialMedia {
    li {
        display: inline-block;
        a {
            color: $metal;
            font-size: 1.5em;
            margin-right: 12px;
            
            &:hover {
                color: $red;
            }
        }
    }
    @include tablet {
        text-align: center;
        
        a {
            font-size: 1.8em;
            margin: 0 8px;
        }
    }
    @include handheld {
        text-align: center;
        
        a {
            font-size: 1.8em;
            margin: 0 12px;
        }
    }
}