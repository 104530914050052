.brandMain {
    background: $black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 260px;
    padding: 30px;
    margin: 0 auto;
    box-shadow: $shadow;
    z-index: 910;
        
    a {
        transition: none!important;
    }
    img {
        width: 100%;
        margin: 0 auto;
    }
        
    @include desktop {
        width: 250px;
    }
    @include small-desktop {
        width: 225px;
        padding: 25px;
    }
    @include tablet {
        right: auto;
        width: 200px;
        padding: 25px;
    }
    @include mobile {
        right: auto;
        width: 180px;
        padding: 25px;
    }
    @include small-mobile {
        right: auto;
        width: 160px;
        padding: 25px;
    }
}
header {
    background: $black;
    padding: 20px 30px;
    position: relative;
    @extend .unformatList;
    z-index: 900;
    
    a {
        color: $white;
        text-transform: uppercase;
        
        &:hover {
            color: $metal;
        }
    }
    .logo {
        width: auto;
        height: 48px;
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        margin: 0 auto;
        
        @include touch {
            left: 20px;
            right: unset;
        }
    }
    .contactUtility {
        float: left;
        
        li {
            display: inline-block;
            vertical-align: middle;
            
            &.social {
                a {
                    color: $metal;
                    font-size: 1.3em;
                    margin-right: 10px;
                    
                    &:hover {
                        color: $red;
                    }
                }
            }
            &.contactLink {
                font-size: 1em;
                font-weight: 700;
                margin-left: 10px;
            }
        }
    }
    .menuUtility {
        float: right;
        text-align: right;
        
        a {
            width: 130px;
            display: block;
            //this prevents jank with the animation
        }
        .line {
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 4px;
            background: $red;
            margin-right: 8px;
            @extend .transition-width;
            position: relative;
            top: 0px;
        }
        span {
            display: inline-block;
            vertical-align: middle;
            font-family: $body;
            font-size: 1.2em;
            font-weight: 700;
            @extend .transition-color;
        }
        
        &:hover {
            .line {
                width: 12px;
            }
            span {
                color: $red;
            }
        }
    }
}

/*.stickyNav {
    @extend header;
    z-index: 899;
    position: absolute;
    width: 100%;
    top: 0;
    
    .logo {
        width: auto;
        height: 48px;
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        margin: 0 auto;
        
        @include handheld {
            left: 20px;
            right: unset;
        }
    }
}*/