.blogPost {
    .postContainer {
        width: 960px;
        background: $white;
        border-top: 5px solid $red;
        margin: 0 auto;
        position: relative;
        top: -200px;
        z-index: 800;
        margin-bottom: -200px;
        
        @include tablet {
            width: 85%;
        }
        @include handheld {
            width: 100%;
            top: 0;
            margin-bottom: 0;
        }
    }
    .share {
        position: absolute;
        top: -22.5px;
        right: 0;
        background: $red;
        height: 40px;
        margin: 0;
        padding: 0 15px;
        display: flex;
        align-items: center;
        
        li {
            display: inline-block;
            color: $white;
            margin: 0 4px;
            @extend .transition-color;
            a {
                color: #FFF;
                &:hover {
                    color: $black;
                }
            }
            &:hover {
                color: $black;
            }
            @include tablet {
                margin: 0 6px;
            }
            @include handheld {
                margin: 0 8px;
            }
            
            &.heading {
                font-family: $body;
                font-size: 15px;
                font-weight: 900;
                margin-right: 8px;
                
                &:hover {
                    color: $white;
                }
            }
            &.copyMessage {
                left: 0;
                position: absolute;
                text-align: right;
                top: 50px;
                width: 100%;
                padding-right: 10px;
                h4 {
                    font-size: 1em;
                    opacity:1;
                    &.fadeOut {
                        opacity: 0;
                        transition: opacity 1000ms;
                    }
                }
            }
        }
    }
    #insightBuilder {
        padding: 80px 80px 40px 80px;
        
        @include small-desktop {
            padding: 60px 40px 20px 40px;
        }
        @include tablet {
            padding: 60px 30px 20px 30px;
        }
        @include handheld {
            padding: 50px 0 20px 0;
        }
        
        p {
            a {
                white-space: pre-wrap;
                
                &:hover {
                    text-decoration: underline!important;
                }
            }
            a::before {
                display: none;
            }
            a::after {
                display: none;
            }
        }
        .videoEmbed {
            max-width: 100%;
        }
    }
    .author {
        .headshot {
            border-radius: $round;
            border: 3px solid $metal;
            width: 46px;
            height: 46px;
            display: inline-block;
            margin-right: 10px;
            @extend .backgroundSettings;
            vertical-align: middle;
            
            @include handheld {
                display: block;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        .credit {
            display: inline-block;
            vertical-align: middle;
            
            @include handheld {
                display: block;
            }
            
            h6 {
                color: $red;
                font-size: 14px;
                font-weight: 700;
                margin: 0;
            }
            p {
                font-size: 14px;
                margin: 0;
                
                span {
                    font-weight: 600;
                }
            }
        }
    }
    .tags {
        padding: 0;
        margin: 0;
        
        li {
            display: inline-block;
            padding: 6px 10px;
            background: $ltGrey;
            font-size: 13px;
            font-weight: 700;
            color: $iron;
            margin-right: 8px;
        }
    }
    .relatedContent {
        border-top: 2px solid $ltGrey;   
        padding-top: 80px;
        margin-bottom: 80px;
        
        @include tablet {
            padding-top: 60px;
            margin-bottom: 60px;
        }
        @include handheld {
            padding-top: 30px;
            margin-bottom: 30px;
        }
        
        a {
            color: $black;
        }
    }
}