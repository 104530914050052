.textBlock {
    h1, h2, h3, h4, h5 {
        &:first-child {
            margin-top: 0;
        }
    }
    p {
        margin-top: 0;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &.offsetLeft {
        width: 50%;
        text-align: left;
        margin: 0;
        
        @include desktop {
            width: 55%;
        }
        @include small-desktop {
            width: 65%;
        }
        @include tablet {
            width: 75%;
        }
        @include handheld {
            text-align: left;
            width: 100%;
        }
    }
    &.offsetCenter {
        width: 50%;
        text-align: center;
        margin: 0 auto;
        display: block;
        
        @include desktop {
            width: 55%;
        }
        @include small-desktop {
            width: 65%;
        }
        @include tablet {
            width: 75%;
        }
        @include handheld {
            text-align: left;
            margin: 0;
            width: 100%;
        }
    }
    &.flush {
        width: 100%;
        text-align: left;
    }
    &.introBlock {
        margin: 100px auto;
        
        @include small-desktop {
            margin: 80px auto;
        }
        @include touch {
            margin: 60px auto;
        }
        
        .divi {
            background: $red;
            height: 5px;
            width: 60px;
            display: block;
            margin: 30px auto 0 auto;
            
            @include handheld {
                display: none;
            }
        }
    }
    .subtitle {
        font-family: $body;
        color: $red;
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
        
        &.red {
            color: $red;
        }
        &.yellow {
            color: $yellow;
        }
        &.blue {
            color: $blue;
        }
    }
    .subtitle + h1 {
        margin-top: 8px;
    }
    .subtitle + h2 {
        margin-top: 8px;
    }
    .subtitle + h3 {
        margin-top: 8px;
    }
    p + h1 {
        margin-top: 1.5em;
    }
    p + h2 {
        margin-top: 1.5em;
    }
    p + h3 {
        margin-top: 1.5em;
    }
    ol + h1 {
        margin-top: 1.5em;
    }
    ol + h2 {
        margin-top: 1.5em;
    }
    ol + h3 {
        margin-top: 1.5em;
    }
    ul + h1 {
        margin-top: 1.5em;
    }
    ul + h2 {
        margin-top: 1.5em;
    }
    ul + h3 {
        margin-top: 1.5em;
    }
    h1, h2, h3, h4, h5, h6 {
        color: $black;
        margin: .5em 0 .35em 0;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
    li {
        margin-bottom: 8px;
        @extend p;
        display: block!important;
    }
    ol {
        counter-reset: li;
        list-style-type: none;
        padding: 0;

        li::before {
            //color: $red!important;
            font-weight: 600;
            content: counter(li, decimal) ".";
            counter-increment: li;
            padding-right: 5px;
        }
        ol {
            counter-reset: second;
            padding-left: 20px;
        }
        ol li::before {
            //color: $red!important;
            font-weight: 600;
            content: counter(second, lower-latin) ".";
            counter-increment: second;
        }
        ol ol {
            counter-reset: third;
            padding-left: 30px;
        }
        ol ol li::before {
            //color: $red!important;
            font-weight: 600;
            content: counter(third, lower-roman) ".";
            counter-increment: third;
        }
        p {
            display: inline list-item;
        }
    }
    ul {
        counter-reset: li;
        list-style-type: none;
        padding: 0;

        li::before {
            content: "▪";
            counter-increment: li;
            padding-right: 5px;
            //color: $red!important;
        }
        ul {
            padding-left: 20px;
        }
        ul li::before {
            //color: $red!important;
        }
        ul ul {
            padding-left: 30px;
        }
        ul ul li::before {
            //color: $red!important;
        }
        p {
            display: inline list-item;
        }
    }
}