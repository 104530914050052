.careers {
	padding: 180px 0 80px 0;

	@include small-desktop {
		padding: 140px 0 80px 0;
	}
	@include tablet {
		padding: 120px 0 80px 0;
	}
	@include handheld {
		padding: 80px 0 80px 0;
	}

	.jobHeader {
		margin-bottom: 60px;

		@include touch {
			border-bottom: 2px solid $ltGrey;
			padding-bottom: 60px;
		}

		.subtitle {
			font-weight: 700;
			color: $red;
			@extend .transition-color;

			&:hover {
				color: $black;
			}
		}
		h1 {
			color: $black;
			margin-top: 5px;
			font-size: 40px;
    
		    @include tablet {
		        font-size: 38px;
		    }
		    @include mobile {
		        font-size: 36px;
		    }
		    @include small-mobile {
		        font-size: 34px;
		    }
		    @include xs-mobile {
		        font-size: 32px;
		    }
		}
		ul {
			padding: 0;
			margin: 0;

			svg {
				color: $red;
				margin-right: 6px;
			}
			li {
				@extend p;
				display: inline-block;
				margin-right: 30px;
				font-weight: 600;

				&:last-of-type {
					margin-right: 0;
					margin-bottom: 0;
				}

				@include touch {
					display: block;
					margin-bottom: 10px;
				}
			}
		}
	}
	.contactForm {
		background-color: $ltGrey;
		padding: 25px;

		@include touch {
			margin-top: 60px;
		}
	}
}