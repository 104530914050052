.videoEmbed {
	max-width: 65%;
	margin: 0 auto;

	@include tablet {
		max-width: 75%;
	}
	@include handheld {
		max-width: 90%;
	}
}