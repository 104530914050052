footer {
    background: $black;
    color: $white;
    @extend .unformatList;
    width: 100vw;
    overflow-x: hidden;

    .footerContent {
        padding: 70px 0;

        @include tablet {
            padding: 60px 0;
        }
        @include handheld {
            padding: 50px 0;
        }
    }
    
    .column {
        @include screen {
            align-items: center;
        }
        @include touch {
            display: block!important;
        }
    }
    .footerLogo {
        display: inline-block;
        margin-bottom: 25px;
        
        @include touch {
            text-align: center;
            display: block;
            margin: 0 auto 30px auto;
        }
        
        img {
            @include handheld {
                width: 92px;
            }
        }
    }
    .footerBadge {
        display: block;
        margin-top: 25px;
        height: 80px;

        @include touch {
            margin: 25px auto 10px auto;
            height: 60px;
            max-width: 80%;
        }
    }
    .summary {
        display: inline-block;
        vertical-align: middle;
        width: 60%;
        position: relative;
        
        @include desktop {
            width: 75%;
        }
        @include small-desktop {
            width: 100%;
        }
        @include touch {
            display: block;
            margin: 0 auto;
            text-align: center;
        }
        @include tablet {
            width: 75%;
        }
        @include handheld {
            width: 85%;
        }
        .description {
            p {
                color: $white;
                margin: 0;

                strong {
                    color: $red;
                    font-weight: 800;
                }
            }
            a {
                color: $white;
                font-weight: 600;

                &:hover {
                    color: $yellow;
                }
            }
        }
    }
    .footerContact {
        text-align: right;
        width: 100%;

        @include touch {
            text-align: center;
        }

        .socialMedia {
            margin-top: 20px;

            li {
                a {
                    margin-right: 0;
                    margin-left: 12px;
                }
            }
            @include tablet {
                a {
                    margin: 0 12px!important;
                }
            }
            @include handheld {                
                a {
                    margin: 0 12px!important;
                }
            }
        }

        .contactInfo {
            font-size: 18px;
            font-weight: 600;
            
            @include tablet {
                font-size: 17px;
                line-height: 2.5em;
            }
            @include handheld {
                font-size: 15px;
                line-height: 2.5em;
                padding: 0 15%;
            }
            a {
                color: $white;
                
                &:hover {
                    color: $metal;
                }
            }
            .divi {
                color: $metal;
                margin: 0 4px;
            }
            .cities {
                span {
                    &:last-of-type {
                        display: none;
                    }
                }
            }
        }
    }
    .legalFooter {
        padding: 30px 0 28px 0;
        border-top: 2px solid $iron;

        .column {
            padding-top: 0!important;
            padding-bottom: 0!important;
        }
        .copyright {
            font-size: 14px;
            font-weight: 500;
            color: $metal;
            margin: 0;
            
            @include touch {
                text-align: center;
                margin: 0 0 10px 0;
            }
        }
        // Nav css in its own file
    }
}