.leadForm {
	h2 {
		text-align: center;
		margin-bottom: 30px;
	}
	&.gunmetal {
		h2 {
			color: $white;
		}
		form {
			label {
				color: $white;
			}
		}
	}

}