.hero {
    background: $black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &.fanciful {
        height: 90vh;
        min-height: 550px;
        max-height: 700px;
        
        @include touch {
            min-height: 400px;
        }
        
        .heroContent {
            display: flex;
            align-items: center;
            justify-content: center;
            
            @include touch {
                flex-direction: column;
            }
            
            .headlineContainer {
                max-width: 40%;
                float: left;
                display: inline-block;
                text-align: left;
                
                @include screen {
                    flex-basis: 375px;
                }
                @include desktop {
                    max-width: 45%;
                }
                @include small-desktop {
                    max-width: 50%;
                }
                @include tablet {
                    max-width: 80%;
                    text-align: center;
                    float: none;
                    margin: 0 auto;
                }
                @include handheld {
                    max-width: 85%;
                    text-align: center;
                    float: none;
                    margin: 0 auto;
                }

                &.noMedia {
                    max-width: 70%;
                    float: none;
                    display: block;
                    text-align: center;

                    @include screen {
                        flex-basis: auto!important;
                    }
                    @include desktop {
                        max-width: 75%;
                    }
                    @include small-desktop {
                        max-width: 80%;
                    }
                    @include tablet {
                        max-width: 85%;
                    }
                    @include handheld {
                        max-width: 90%;
                    }
                }
            }
            h1 {
                font-size: 60px;
                line-height: 1em;
                text-transform: uppercase;
                margin: 0;
                
                @include small-desktop {
                    font-size: 52px;
                }
                @include tablet {
                    font-size: 44px;
                }
                @include handheld {
                    font-size: 36px;
                }
            }
            .divi {
                height: 100%;
                width: 6px;
                background: $red;
                float: left;
                margin: 0 40px;
                //@extend .smooth;
                
                @include touch {
                    display: none;
                }
            }
            .videoPrompt {
                float: left;
                @include touch {
                    margin: 0 auto;
                    display: block;
                    text-align: center;
                    float: none;
                }
            }
            .videoButton {
                @include touch {
                    margin: 40px auto 0 auto;
                    float: none;
                }
            }
        }
        .scrollDown {
            position: absolute;
            bottom: 40px;
            right: 40px;
            z-index: 4;
            width: 32px;
            height: 32px;
            background: $red;
            border-radius: $round;
            display: flex;
            align-items: center;
            justify-content: center;
            @extend .transition-transform;
            
            .svg-inline--fa {
                color: $white;
                font-size: 1em;
            }
            
            @include touch {
                display: none;
            }
            
            &:hover {
                transform: scale(.94);
            }
        }
    }
    &.large {
        height: 70vh;
        min-height: 450px;
        max-height: 700px;
        border-bottom: 5px solid $red;
        
        &.border-red {
            border-bottom: 5px solid $red;
        }
        &.border-yellow {
            border-bottom: 5px solid $yellow;
        }
        &.border-blue {
            border-bottom: 5px solid $blue;
        }
        
        h1 {
            @include screen {
                position: relative;
                top: 30px;
                margin: 0;
            }
        }
    }
    &.medium {
        height: 50vh;
        min-height: 350px;
        max-height: 600px;
        border-bottom: 5px solid $red;
        
        &.border-red {
            border-bottom: 5px solid $red;
        }
        &.border-yellow {
            border-bottom: 5px solid $yellow;
        }
        &.border-blue {
            border-bottom: 5px solid $blue;
        }
        
        h1 {
            @include screen {
                position: relative;
                top: 30px;
                margin: 0;
            }
        }
    }
    &.small {
        height: 30vh;
        min-height: 250px;
        max-height: 500px;
        border-bottom: 5px solid $red;
        
        &.border-red {
            border-bottom: 5px solid $red;
        }
        &.border-yellow {
            border-bottom: 5px solid $yellow;
        }
        &.border-blue {
            border-bottom: 5px solid $blue;
        }
        
        h1 {
            @include screen {
                position: relative;
                top: 30px;
                margin: 0;
            }
        }
    }
    &.blogHero {
        height: 90vh;
        min-height: 500px;
        max-height: 800px;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 500;
        
        @include handheld {
            height: 50vh;
        }
        
        .leadContent {
            text-align: center;
            position: relative;
            top: -60px;
            z-index: 50;
            max-width: 960px;
            
            @include tablet {
                max-width: 90%;
            }
            @include handheld {
                text-align: left;
                top: 0;
                max-width: 90%;
            }
        }
        h1 {
            margin-bottom: 10px;
            
            @include small-mobile {
                font-size: 34px;
            }
        }
        span {
            font-family: $body;
            font-size: 20px;
            font-weight: 700;
            color: $white;
            
            @include touch {
                font-size: 17px;
            }
            
            .divi {
                margin: 0 10px;
                color: $red;
            }
        }
    }
    
    .heroContent {
        text-align: center;
        z-index: 6;
        
        h1 {
            text-transform: uppercase;
        }
    }
    .linkBuilder {
        position: absolute;
        z-index: 5;
        left: 40px;
        bottom: 40px;
        
        @include touch {
            left: 0;
            right: 0;
            text-align: center;
        }
        
        li {
            display: inline-block;
            vertical-align: middle;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 700;
            margin-right: 30px;
            
            @include handheld {
                font-size: 17px;
                margin: 0 15px;
            }
            
            a {
                color: $white;
                border-bottom: 5px solid transparent;
                padding-bottom: 8px;
                
                &:hover {
                    border-bottom: 4px solid $red;
                }
            }
        }
    }
    .subnav {
        background: $red;
        position: absolute;
        bottom: -42px;
        text-align: center;
        z-index: 5;
        padding: 8px 15px;
        border-radius: $radius;
        
        @include touch {
            display: none;
        }
        
        li {
            font-size: 1em;
            font-weight: 700;
            margin: 0 10px;
            text-transform: uppercase;
            display: inline-block;
            
            a {
                color: $white;
                
                &:hover {
                    color: $black;
                }
            }
        }
    }
    .heroLogo {
        position: absolute;
        z-index: 3;
        max-width: 150px;
        max-height: 65px;
        bottom: 40px;
        right: 40px;
        
        @include handheld {
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 120px;
            max-height: 50px;
            bottom: 30px;
        }
    }
    .bgImg {
        @extend .backgroundSettings;
        z-index: 1;
    }
}