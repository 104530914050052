.spacer {
    display: block;
    width: 100%;
    padding: 0!important;
    
    .medium {
        height: 80px;
        
        @include handheld {
            height: 60px;
        }
    }
    .large {
        height: 120px;
        
        @include handheld {
            height: 100px;
        }
    }
    .small {
        height: 40px;
        
        @include handheld {
            height: 30px;
        }
    }
    .divider {
        hr {
            height: 2px;
            background: $ltGrey;
            margin: 80px 0;
            
            @include handheld {
                margin: 50px 0;
            }
        }
    }
    .pattern {
        height: 32px;
        background-repeat: no-repeat;
        background-size: cover;
        
        @include large-desktop {
            background-size: 80%;
            background-repeat: repeat-x;
        }
        @include tablet {
            background-size: 150%;
        }
        @include tablet {
            background-size: 150%;
        }
        @include handheld {
            background-size: 200%;
        }
    }
}