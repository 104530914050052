.expander {
    margin: 0;
    
    .accordion {
        margin: 0;
    }
    li {
        margin-top: 5px!important;
    }
    .uk-accordion-title {
        font-family: $primary;
        font-size: 24px;
        color: $white;
        background-color: $iron;
        padding: 10px;
                    
        @include tablet {
            font-size: 22px;
        }
        @include handheld {
            font-size: 19px;
        }
        .span {
            position: relative;
            top: 1px;
        }
    }
    .uk-accordion-content {
        background-color: $ltGrey;
        margin-top: 0;
        padding: 20px;
        
        p {
            margin-bottom: 12px;
            
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .button {
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }
    .uk-accordion-title::before {
        background-image: url(https://cast-iron.frb.io/assets/img/open.svg);
        background-size: 55%;
    }
    .uk-open>.uk-accordion-title::before {
        background-image: url(https://cast-iron.frb.io/assets/img/close.svg);
        background-size: 55%;
    }
}