// Resets
body {
    font-family: $body;
    //overflow-x: hidden;
}

[x-cloak] { display: none !important; }

//Bulma Overrides
.container {
    &.is-widescreen {
        @include xs-mobile {
            padding-left: 20px!important;
            padding-right: 20px!important;
        }
        @include small-mobile {
            padding-left: 20px!important;
            padding-right: 20px!important;
        }
        @include mobile {
            padding-left: 30px!important;
            padding-right: 30px!important;
        }
        @include tablet {
            padding-left: 30px!important;
            padding-right: 30px!important;
        }
        @include small-desktop {
            padding-left: 30px!important;
            padding-right: 30px!important;
        }
    }
}
.nopad {
    padding: 0!important;
}

// Bacward Compatibility
header, nav, main, footer, article, section, aside {
   display:block;
}

// Utilities
.contentGroup {
    padding-bottom: 100px;

    @include small-desktop {
        padding-bottom: 80px;
    }
    @include touch {
        padding-bottom: 60px;
    }
}
.contentFeed {
    padding: 100px 0;

    @include small-desktop {
        padding: 80px 0;
    }
    @include touch {
        padding: 60px 0;
    }
}
.transition-all {
    transition: color $transition ease-in-out, transform $transition ease-in-out, background $transition ease-in-out, border $transition ease-in-out, opacity $transition ease-in-out, filter $transition ease-in-out;
}
.transition-color {
    transition: color $transition ease-in-out;
}
.transition-opacity {
    transition: opacity $transition ease-in-out;
}
.transition-border {
    transition: border $transition ease-in-out;
}
.transition-transform {
    transition: transform $transition ease-in-out;
}
.transition-background {
    transition: background $transition ease-in-out;
}
.transition-width {
    transition: width $transition ease-in-out;
}
.clear {
    clear: both;
}
.overlay {
    &.cover {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &.darken {
        background: rgba(0, 0, 0, 0.4);
        z-index: 2;
    }
    &.dark {
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;
    }
}
.backgroundSettings {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.unformatList {
    ol,ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}
.uk-offcanvas-overlay:before {
    background: rgba(37, 47, 56, 0.75)!important;
}
.intrinsic-container {
    position: relative;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.intrinsic-container-16x9 {
    padding-bottom: 56.5%;
}

//Dev Notes
.notes {
    /*display: block;
    width: 100%;
    color: $metal;
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0;
    text-align: center;
    letter-spacing: 1em;*/
    display: none;
}
