h1,h2,h3,h4,h5,h6,p {
    //margin: 0;
    //padding: 0;
}
h1,h2,h3,h4,h5,h6 {
    font-family: $primary;
    font-weight: 700;
    color: $black;
    line-height: 1.1em;
    letter-spacing: .02em;
    font-variant-numeric: lining-nums;
}
h1 { 
    font-size: 48px;
    color: $white;    
    
    @include tablet {
        font-size: 40px;
    }
    @include mobile {
        font-size: 38px;
    }
    @include small-mobile {
        font-size: 36px;
    }
    @include xs-mobile {
        font-size: 34px;
    }
} 
h2 { 
    font-size: 40px;
    color: $black;
    
    @include tablet {
        font-size: 36px;
    }
    @include mobile {
        font-size: 34px;
    }
    @include small-mobile {
        font-size: 30px;
    }
} 
h3 { 
    font-size: 30px;
    color: $black;
    
    @include mobile {
        font-size: 28px;
    }
    @include small-mobile {
        font-size: 26px;
    }
} 
h4 { 
    font-size: 26px;
     color: $black;
    
    @include mobile {
        font-size: 24px;
    }
    @include small-mobile {
        font-size: 22px;
    }
}
h5 { 
    font-size: 20px;
     color: $black;
    
    @include mobile {
        font-size: 19px;
    }
    @include small-mobile {
        font-size: 18px;
    }
}
p { 
    font-family: $body;
    font-size: 18px;
    font-weight: 400;
    color: $black;
    
    @include tablet {
        font-size: 17px;
    }
    @include handheld {
        font-size: 16px;
    }
    
    a {
        cursor: pointer;
        font-size: 18px;
        position: relative;
        white-space: nowrap;
        color: $red;
    }
    a:hover {
        color: $red;
    }
    a::before,
    a::after {
        position: absolute;
        width: 100%;
        height: 2px;
        background: currentColor;
        top: 100%;
        left: 0;
        pointer-events: none;
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        //transition: transform 0.3s;
        @extend .transition-transform;
    }
    a::before {
        content: '';
    }
    a:hover::before {
        transform-origin: 0% 50%;
        transform: scale3d(1, 1, 1);
    }
}

//Resets 
strong {
    color: inherit;
}
em {
    color: inherit;
}

//Base Links
a:link {
    text-decoration: none!important;
    @extend .transition-all;
}