#dma {
    h1 {
        margin: 0;
    }
    .hero {
        .callButton {
            position: absolute;
            bottom: -22px;
            z-index: 30;
        }
    }
}
.cityDma {
    .cityTeams {
        ul {
            padding: 0;
            margin: 0;
        }
        .headerRow {
            margin-top: 40px;
            li {
                padding: 10px;
                width: 49%;
                display: inline-block;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 600;
                vertical-align: middle;
                
                @include handheld {
                    font-size: 13px;
                }
            }
        }
        .teamsList {
            margin-bottom: 40px;
            li {
                @include handheld { 
                    padding: 10px;
                }
                &.odd {
                    background-color: $ltGrey;
                    border-left: 3px solid $ltGrey;
                    @extend .transition-all;
                }
                &.even {
                    background-color: $offWhite;
                    border-left: 3px solid $offWhite;
                    @extend .transition-all;
                }
                &:hover {
                    border-left: 3px solid $red;
                }
                a {
                    color: $black;
                }
                span {
                    width: 49%;
                    display: inline-block;
                    padding: 10px;
                    vertical-align: top;
                    
                    @include handheld {
                        padding: 5px;
                        font-size: 15px;
                    }
                }
            }
        }
        &.alt {
            .headerRow {
                li {
                    width: 32%;
                }
            }
            .teamsList {
                span {
                    width: 32%;
                }
            }
        }
    }
}
.teamDma {
    .dmaContent {
        .intro {
            width: 65%;
            text-align: center;
            margin: 0 auto;
            display: block;

            @include desktop {
                width: 70%;
            }
            @include small-desktop {
                width: 75%;
            }
            @include tablet {
                width: 85%;
            }
            @include handheld {
                text-align: left;
                margin: 0;
                width: 100%;
            }
            h2 {
                margin-top: 0;
            }
        }
        .moreInfo {
            h4 {
                margin-top: 30px;
            }
            /*.accordion {
                margin-bottom: 40px;
            }*/
        }
    }
}
.callButton {
    position: relative;
    display: flex;
    justify-content: center;
    
    .label {
        margin: 0;
        background: $red;
        color: $white;
        padding: 0 15px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        float: left;
        @extend .transition-background;
    }
    .icon {
        background: $dkRed;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        float: left;
        @extend .transition-background;
        
        svg {
            color: $white;
        }
    }
    &:hover {
        .label {
            background: $dkRed;
        }
        .icon {
            background: $red;
        }
    }
}