.imageBlock {
    .gallery {
        max-width: 1040px;
        margin: 0 auto;

        @include touch {
            width: 88%;
        }
    }
    .singleImage {
        display: block;
        max-width: 1040px;
        margin: 0 auto;

        @include touch {
            width: 100%;
        }
    }
}