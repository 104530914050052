.uk-thumbnav {
    margin-top: 15px;
                    
    @include screen {
        justify-content: center;
    }
    li {
        @extend .transition-all;

        &:hover {
            .thumbnail {
                border: 3px solid $metal;
            }   
        }
        &.uk-active {
            .thumbnail {
                border: 3px solid $red;
            }
        }
    }
    .thumbnail {
        border-radius: $round;
        border: 3px solid $white;
        width: 64px;
        height: 64px;
        @extend .backgroundSettings;
        @extend .transition-border;
                        
        @include handheld {
            width: 42px;
            height: 42px;
        }
        &:hover {
            border: 3px solid $metal;
        }
    }
}
.uk-thumbnav>*>::after {
    background: transparent;
}