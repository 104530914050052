#team {
    .sectionTitle {
        margin: 0;
    }
    .teamMember { 
        margin-bottom: 15px;
        h3 {
            font-size: 24px;
            margin: 20px 0 10px 0;
            
            @include handheld {
                font-size: 22px;
            }
        }
        h4 {
            margin: 0;
            color: $iron;
            font-family: $body;
            font-size: 18px;
        }
        .headshot {
            position: relative;
            width: 100%;
            height: 300px;
            
            .hsPrimary {
                @extend .backgroundSettings;
                /*@extend .transition-all;*/
                opacity: 1;
                z-index: 5;
            }
            /*.hsHover {
                @extend .backgroundSettings;
                @extend .transition-all;
                opacity: 0;
                z-index: 6;
            }*/
        }
        /*&:hover {
            .hsHover {
                opacity: 1;
            }
            .hsPrimary {
                opacity: 0;
            }
        }*/
    }
}