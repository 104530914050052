.logoGrid {
    h3 {
        font-family: $body;
        font-size: 20px;
        color: $metal;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .04em;
        
        @include touch {
            font-size: 16px;
        }
    }
    .svg-inline--fa {
        color: $metal;
        @extend .transition-color;
        font-size: 18px;

        &:hover {
            color: $iron;
        }
    }
    .logoCard {
        border: 1px solid $metal;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        @extend .transition-all;

        img {
            max-width: 70%;
            max-height: 70%;
            display: block;
            margin: 0 auto;
            padding: 40px 15px;
            @extend .transition-all;
        }
    }
    ul {
        li {
            transition: opacity $transition ease-in-out;
            a {
                transition: none;
            }
        }
    }
}
.uk-slider-items:hover li:not(:hover) {
    filter: grayscale(1);
    opacity: .4;
}
.logoGrid:hover .logoCard:not(:hover) {
    filter: grayscale(1);

    img {
        opacity: .4;
    }
}

.uk-position-center-left-out {
    @include touch {
        display: none;
    }
}
.uk-position-center-right-out {
    @include touch {
        display: none;
    }
}

.partner {
    border: 1px solid $metal;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    
    img {
        max-width: 70%;
        max-height: 70%;
        display: block;
        margin: 0 auto;
        padding: 40px 15px;
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .overlay {
        opacity: 0;
        @extend .transition-opacity;
    }
    .actionIcon {
        opacity: 0;
        @extend .transition-all;
        transform: translateY(300px);
        background: $red;
        color: $white;
        width: 42px;
        height: 42px;
        padding: 8px;
        position: absolute;
        z-index: 10;
    }
    &:hover {
        .overlay {
            opacity: .7;
        }
        .actionIcon {
            opacity: 1;
            transform: translateY(0px)
        }
    }
}