.contact {
    .contactSections {
        display: flex;

        @include touch {
            display: block;
        }
    }
    .contactInfo {
        width: 50%;
        padding: 80px;

        @include tablet {
            width: 100%;
            padding: 60px;
        }
        @include mobile {
            width: 100%;
            padding: 40px;
        }
        @include small-mobile {
            width: 100%;
            padding: 30px;
        }
    }
    .contactForm {
        width: 50%;
        background: $black;
        padding: 80px;

        @include tablet {
            width: 100%;
            adding: 60px;
        }
        @include mobile {
            width: 100%;
            padding: 40px;
        }
        @include small-mobile {
            width: 100%;
            padding: 30px;
        }

        h3 {
            font-size: 26px;
            color: $white;
        }
        label {
            color: $white;
        }
        input {
            &:focus-visible {
                outline-offset: 2px;
                outline-width: 2px;
                outline-style: solid;
                outline-color: $iron;
                background-color: $white;
            }
        }
    }
}
.contactGlobal {
    .body {
        margin-bottom: 20px;
    }
    .divi {
        background-color: $ltGrey;
        height: 5px;
        width: 60px;
        margin-bottom: 20px;
      }
    dl {
        color: $black;
        overflow: hidden;
        line-height: 1.6em;
        font-size: 17px;

        dt {
          float: left;
          margin-right: .3125rem;
          &::after {
            content: ':';
          }
        }
    }
    a {
        color: $black;
        @extend .transition-color;

        &:hover {
            color: $iron;
        }
    }
}

#contact-forms {
    .tabs {
        padding: 0;
        width: 100%;
        white-space: normal;
        --grid-layout-gap: 20px;
        --grid-column-count: 4;
        --grid-item--min-width: 170px;
        --gap-count: calc(var(--grid-column-count) - 1);
        --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
        --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
        grid-gap: var(--grid-layout-gap);

        @include handheld {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        a {
            text-decoration: none;
            border-bottom: none;
            text-transform: uppercase;
            background-color: $iron;
            font-size: 1em;
            font-weight: 700;
            color: $white;
        }

        .active a {
            background-color: $ltGrey;
            color: $black;
        }
    }
}
