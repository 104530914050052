.featureBlocks {
    display: flex;

    &.gunmetal {
        h4 {
            color: $white;
        }
        h5 {
            color: $metal;
        }
    }
}
.featureBlock {
    h4 {
        margin: 20px 0 10px 0;
    }
    h5 {
        margin: 0;
        color: $iron;
        font-family: $body;
        font-size: 18px;
        line-height: 1.3em;
    }
    &.hasLink {
        .imgWrapper {
            overflow: hidden;
        }
        .overlay {
            opacity: 0;
            @extend .transition-opacity;
        }
        .actionIcon {
            opacity: 0;
            @extend .transition-all;
            transform: translateY(300px);
            background: $red;
            color: $white;
            width: 42px;
            height: 42px;
            //padding: 8px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
        }
        &:hover {
            .overlay {
                opacity: .7;
            }
            .actionIcon {
                opacity: 1;
                transform: translateY(0px)
            }
        }
    }
}