.jobPosts {
	.jobCard {
		border-bottom: 2px solid $ltGrey;
		padding: 15px 0;
		font-weight: 500;
		@extend .transition-border;

		&:first-of-type {
			border-top: 2px solid $ltGrey;
		}

		@include touch {
			padding: 30px 0;
		}
	}
	a {
		color: $black;

		&:hover {
			.cta {
				color: $red;
			}
			.jobCard {
				border-bottom: 2px solid $red;
			}
		}
	}
	.jobTitle {
		text-align: left;
		font-weight: 700;
	}
	.location {
		text-align: center;

		@include handheld {
			text-align: left;
		}
	}
	.cta {
		text-align: right;
		font-weight: 700;
		@extend .transition-color;

		@include handheld {
			text-align: left;
			color: $red;
		}
		svg {
			margin-left: 6px;
		}
	}
	.column {
		@include touch {
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
}