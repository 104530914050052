.svGroup {
    &.gunmetal {
        .svContent {
            h1, h2, h3, h4, h5, h6, p, li {
                color: $white;
            }
        }
        .thumbnail {
            border: 3px solid $black;
        }
    }
    
    .splitView {
        margin-bottom: 80px;
        
        @include tablet {
            margin-bottom: 60px;
        }
        @include handheld {
            margin-bottom: 40px;
        }
        
        &:last-child  {
            margin-bottom: 0;
        }
        
        .columns {
            align-items: center;
        }
        .svContent {
            h2 {
                margin-bottom: 30px;
            }
            .button {
                margin-top: 30px;
            }
        }
        
        &.contentRight {
            .columns {
                flex-direction: row;
            
                @include touch {
                    flex-direction: row-reverse;
                }
            }
            .svContent {
                @include screen {
                    padding-left: 5%;
                }
            }
        }
        &.contentLeft {
            .columns {
                flex-direction: row-reverse;
            }
            .svContent {
                @include screen {
                    padding-right: 5%;
                }
            }
        }
        
        &.float {
            //nada
        }

        &.flushBottom {
            margin-bottom: 0;
            .columns {
                align-items: stretch;
            }
            .svMedia {
                display: flex;
            }
            .svContent {
                padding-bottom: 80px;
            }
            .imgWrapper {
                width: 100%;
                @extend .backgroundSettings;
                
                @include tablet {
                    height: 500px;
                }
                @include handheld {
                    height: 300px;
                }
            }
        }
        &.gallery {
            .svMedia {
                //moved to partial
            }
        }
        &.videoType {
            .svMedia {
                position: relative;
                //margin-bottom: -28px;
            }
        }
    }
}