.cardGroup {
    display: flex;

    &.grey {
        .standardCard {
            .subtitle {
                background: $metal;
                color: $white;
            }
        }
    }
    &.gunmetal {
        .heroCard {
            background: $ltGrey;

            .subtitle {
                color: $iron;
            }
            h2 {
                color: $black;
            }
            .description {
                color: $black;

                p {
                    color: $black;
                }
            }
            .button {
                color: $black;
            }
        }
        .standardCard {
            .subtitle {
                background: $iron;
                color: $white;
            }
            h3 {
                color: $white;
            }
            .description {
                color: $white;

                p {
                    color: $white;
                }
            }
            .button {
                color: $white;
            }
        }
        .miniCard {
            h3 {
                color: $white;
            }
            .description {
                color: $white;

                p {
                    color: $white;
                }
            }
            .button {
                color: $white;
            }
        }
    }
}

.card {
    border-radius: 0;
    box-shadow: none;
    position: relative;
    background: none;
    
    .imgWrapper {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
            width: 100%;
        }
    }
    .videoPrompt {
        position: absolute;
    }
    
    &.heroCard {
        background: $black;
        padding: 40px;
        height: 100%;
        
        @include tablet {
            padding: 35px;
        }
        @include handheld {
            padding: 25px;
        }
        
        .divi {
            //background: $red;
            height: 5px;
            width: 60px;
            margin-bottom: 20px;
            
            @include handheld {
                margin-bottom: 15px;
            }
        }
        .subtitle {
            color: $metal;
            font-size: 18px;
            font-family: $body;
            font-weight: 700;
            text-transform: uppercase;
            
            @include tablet {
                font-size: 18px;
            }
            @include handheld {
                font-size: 17px;
            }
        }
        h2 {
            color: $white;
            margin: 10px 0 0 0;

            a {
                color: inherit;
            }
        }
        .imgWrapper {
            margin: 40px 0 30px 0;
            
            @include touch {
                margin: 30px 0;
            }
        }
        .description {
            color: $white;
            
            p {
                color: $white;
            }
        }
        .button {
            color: $white;
            
            &:hover {
                //color: $red;
            }
        }

        &.compact {
            @include screen {
                padding: 30px;

                .subtitle { 
                    font-size: 16px;
                }
                h2 {
                    font-size: 32px;
                }
                .imgWrapper { 
                    margin: 30px 0 25px 0;
                }
            }
        }

    }
    &.standardCard {
        margin-top: 10px;
        .subtitle {
            background: $ltGrey;
            font-family: $primary;
            font-size: 20px;
            font-weight: 600;
            padding: 10px 10px 8px 10px;
            position: relative;
            display: block;
            float: left;
            margin: 0;
            z-index: 2;
            
            &:after {
                content: '';
                  width: 55%;
                  height: 100%;
                  position: absolute;
                  background: inherit;
                  z-index: -1;
                  top: 0;
                  transform-origin: right bottom;
                  transform: skewX(25deg);
            }
        }
        .imgWrapper {
            margin: 0 0 25px 0;
        }
        h3 {
            font-size: 24px;
            margin: 0 0 20px 0;
            
            a {
                color: inherit;
            }
        }
        p {
            .slant {
                //color: $red;
                font-weight: 500;
            }
        }
        .button {
            &:hover {
                //color: $red;
            }
        }
    }
    &.miniCard {
        .imgWrapper {
            margin: 0;
        }
        h3 {
            font-size: 24px;
            margin: 20px 0 10px 0;
        }
        .description {
            margin: 0;
        }
        .button {
            &:hover {
                //color: $red;
            }
        }
    }
    &.mediaCard {
        background: $ltGrey;
        height: 100%;
        
        .cardContent {
            padding: 15px;
            
            h4 {
                text-align: center;
                margin: 50px 0 75px 0;
            }
            .utilityBar {
                margin: 0;
                padding: 15px;
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                right: 0;
                
                li {
                    display: inline-block;
                    margin-right: 15px;
                    color: $iron;
                    
                    &.action {
                        float: right;
                        //position: absolute;
                        //right: 15px;
                        //bottom: 15px;
                        margin: 0;
                        background: $iron;
                        border-radius: $round;
                        color: $white;
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 15px;
                        @extend .transition-all;
                    }
                }
            }
        }
        .hvrBorder {
            width: 0;
            height: 4px;
            background: $red;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
            @extend .transition-width;
        }
        &:hover {
            .utilityBar { 
                li {
                    &.action {
                        background: $red;
                    }
                }
            }
            .hvrBorder {
                width: 100%;
            }
        }
    }
    &.videoCard {
        h4 {
            margin: 20px 0 0 0;
        }
    }
    &.blog {
        background: $black;
        padding: 40px;
        height: 100%;
        
        @include tablet {
            padding: 35px;
        }
        @include handheld {
            padding: 25px;
        }
        
        .divi {
            background: $red;
            height: 5px;
            width: 60px;
            margin-bottom: 20px;
            
            @include handheld {
                margin-bottom: 15px;
            }
        }
        .meta {
            color: $metal;
            font-weight: 700;
            margin-bottom: 8px;
            display: block;
            
            .slant {
                color: $red;
                margin: 0 4px;
            }
        }
        a {
            color: $white;
        }
        h2 {
            color: $white;
            margin: 0;
        }
        .imgWrapper {
            margin: 40px 0 30px 0
        }
        .description {
            color: $white;
            margin-bottom: 40px;
            
            p {
                color: $white;
            }
            .author {
                font-family: $body;
                font-weight: 600;
                font-size: 16px;
                color: $metal;
                display: block;
            }
        }
        .button {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    &.resource {
        border-bottom: 1px solid $ltGrey;
        padding-bottom: 40px;
        margin-bottom: 40px;
        
        .meta {
            color: $iron;
            font-weight: 700;
            margin-bottom: 8px;
            display: block;
            
            .slant {
                color: $red;
                margin: 0 4px;
            }
        }
        h2 {
            margin: 0 0 15px 0;
        }
        a {
            color: $black;
        }
        p {
            margin: 0 0 30px 0;
        }
        .button {
            color: $white;
        }
    }
    &.featureCard {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 1200px;
        margin: 0 auto;

        @include handheld {
            display: block;
        }

        .contentBox {
            background-color: $black;
            padding: 80px;
            width: 50%;

            @include small-desktop {
                padding: 60px;
            }
            @include tablet {
                padding: 50px;
            }
            @include handheld {
                width: 100%;
                padding: 40px;
            }

            h3 {
                color: $white;
            }
            ul {
                list-style-type: none!important;
                padding: 0;
                margin: 20px 0;

                .svg-inline--fa {
                    color: $blue;
                    margin-right: 4px;
                }
                li {
                    font-size: 20px;
                    color: $white;
                    list-style-type: none;
                    padding: 4px 0;
                }
                li::before {
                    content: "";
                    padding-right: 0px;
                }
            }
            p {
                color: $white;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.1em;
                margin: 25px 0 0 0;
            }
        }
        .featureImage {
            @extend .backgroundSettings;
            width: 50%;
            display: flex;
            flex: 1;
            align-self: stretch;
            border-top: 15px solid $white;
            border-bottom: 15px solid $white;

            @include handheld {
                width: 100%;
                display: block;
                border: none;
                height: 350px;
            }
        }
    }
}