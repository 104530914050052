.legal {
    margin: 0;
    text-align: right;
    
    @include touch {
        text-align: center;
        margin: 0;
    }
    li {
        display: inline-block;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        margin-left: 10px;
        margin-right: 0!important;
        
        @include touch {
            margin-right: 8px;
            margin-left: 8px;
            font-size: 13px;
        }
        a {
            color: $metal;
            
            &:hover {
                color: $ltGrey;
            }
        }
    }
}