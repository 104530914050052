.grey {
    background: $ltGrey;
    padding: 80px 0;
}
.gunmetal {
    background: $black;
    padding: 80px 0;
}
.color-red {
    color: $red;
}
.color-blue {
    color: $blue;
}
.color-yellow {
    color: $yellow;
}

.bg {
    &.highlight-red {
        background-color: $red!important;
    }
    &.highlight-blue {
        background-color: $blue!important;
    }
    &.highlight-yellow {
        background-color: $yellow!important;
    }
    &.highlight-iron {
        background-color: $iron!important;
    }
    &.highlight-metal {
        background-color: $metal!important;
    }
}
.clr {
    &.highlight-red {
        color: $red!important;
    }
    &.highlight-blue {
        color: $blue!important;
    }
    &.highlight-yellow {
        color: $yellow!important;
    }
    &.highlight-iron {
        color: $iron!important;
    }
    &.highlight-metal {
        color: $metal!important;
    }
}
.bdr {
    &.highlight-red {
        border-color: $red!important;
    }
    &.highlight-blue {
        border-color: $blue!important;
    }
    &.highlight-yellow {
        border-color: $yellow!important;
    }
    &.highlight-iron {
        border-color: $iron!important;
    }
    &.highlight-metal {
        border-color: $metal!important;
    }
}
.btn {
    &.highlight-red {
        .button {
            background-color: $red!important;
            &:hover {
                background-color: $dkRed!important;
            }
        }
    }
    &.highlight-blue {
        .button {
            background-color: $blue!important;
            &:hover {
                background-color: $dkBlue!important;
            }
        }
    }
    &.highlight-yellow {
        .button {
            background-color: $yellow!important;
            &:hover {
                background-color: $dkYellow!important;
            }
        }
    }
    &.highlight-iron {
        .button {
            background-color: $iron!important;
            &:hover {
                background-color: $metal!important;
            }
        }
    }
    &.highlight-metal {
        .button {
            background-color: $metal!important;
            &:hover {
                background-color: $iron!important;
            }
        }
    }
}
.slash {
    &.highlight-red {
        span {
            color: $red!important;
        }
        &:hover {
            color: $red!important;
        }
    }
    &.highlight-blue {
        span {
            color: $blue!important;
        }
        &:hover {
            color: $blue!important;
        }
    }
    &.highlight-yellow {
        span {
            color: $yellow!important;
        }
        &:hover {
            color: $yellow!important;
        }
    }
    &.highlight-iron {
        span {
            color: $iron!important;
        }
        &:hover {
            color: $iron!important;
        }
    }
    &.highlight-metal {
        span {
            color: $metal!important;
        }
        &:hover {
            color: $metal!important;
        }
    }
}
.videoButton {
    &.highlight-red {
        .svg-inline--fa {
            color: $red!important;
        }
        &:hover {
            background: $red!important;
            
            .svg-inline--fa {
                color: $white!important;
            }
        }
    }
    &.highlight-blue {
        .svg-inline--fa {
            color: $blue!important;
        }
        &:hover {
            background: $blue!important;
            
            .svg-inline--fa {
                color: $white!important;
            }
        }
    }
    &.highlight-yellow {
        .svg-inline--fa {
            color: $yellow!important;
        }
        &:hover {
            background: $yellow!important;
            
            .svg-inline--fa {
                color: $white!important;
            }
        }
    }
    &.highlight-iron {
        .svg-inline--fa {
            color: $iron!important;
        }
        &:hover {
            background: $iron!important;
            
            .svg-inline--fa {
                color: $white!important;
            }
        }
    }
    &.highlight-metal {
        .svg-inline--fa {
            color: $metal!important;
        }
        &:hover {
            background: $metal!important;
            
            .svg-inline--fa {
                color: $white!important;
            }
        }
    }
}

.highlight-red {
        a {
            color: $red;
        }
        ol {
            li::before {
                color: $red!important;
            }
            ol li::before {
                color: $red!important;
            }
            ol ol li::before {
                color: $red!important;
            }
        }
        ul {
            li::before {
                color: $red!important;
            }
            ul li::before {
                color: $red!important;
            }
            ul ul li::before {
                color: $red!important;
            }
        }
    }
.highlight-blue {
        a {
            color: $blue;
        }
        ol {
            li::before {
                color: $blue!important;
            }
            ol li::before {
                color: $blue!important;
            }
            ol ol li::before {
                color: $blue!important;
            }
        }
        ul {
            li::before {
                color: $blue!important;
            }
            ul li::before {
                color: $blue!important;
            }
            ul ul li::before {
                color: $blue!important;
            }
        }
    }
.highlight-yellow {
        a {
            color: $yellow;
        }
        ol {
            li::before {
                color: $yellow!important;
            }
            ol li::before {
                color: $yellow!important;
            }
            ol ol li::before {
                color: $yellow!important;
            }
        }
        ul {
            li::before {
                color: $yellow!important;
            }
            ul li::before {
                color: $yellow!important;
            }
            ul ul li::before {
                color: $yellow!important;
            }
        }
    }
.highlight-iron {
        a {
            color: $red;
        }
        ol {
            li::before {
                color: $red!important;
            }
            ol li::before {
                color: $red!important;
            }
            ol ol li::before {
                color: $red!important;
            }
        }
        ul {
            li::before {
                color: $red!important;
            }
            ul li::before {
                color: $red!important;
            }
            ul ul li::before {
                color: $red!important;
            }
        }
    }
.highlight-metal {
        a {
            color: $red;
        }
        ol {
            li::before {
                color: $red!important;
            }
            ol li::before {
                color: $red!important;
            }
            ol ol li::before {
                color: $red!important;
            }
        }
        ul {
            li::before {
                color: $red!important;
            }
            ul li::before {
                color: $red!important;
            }
            ul ul li::before {
                color: $red!important;
            }
        }
    }
    .pattern {
        background-image: url('/assets/img/pattern.svg');
    }