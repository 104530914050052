/*----BUTTONS----*/
.button {
    font-family: $body;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    text-decoration: none;
    background: none;
    border: none;
    box-shadow: none;
    height: auto;
    cursor: pointer;
    padding: 0;
    @extend .transition-all;
    
    @include tablet {
        font-size: 16px;
    }
    @include mobile {
        font-size: 15px;
    }
    
    &.primary {
        background: $red;
        color: $white;
        border-radius: 0;
        text-align: center;
        padding: 8px 20px;
        
        @include touch {
            padding: 5px 18px;
        }
        @include handheld {
            padding: 5px 18x;
        }
        &:hover {
            background: $dkRed;
        }
    }
    &.slash {
        color: $black;
        
        span {
            color: $red;
            position: relative;
            top: -1px;
        }
        &:hover {
            color: $red;
        }
    }
    &.darkPill {
        background: $black;
        border: 3px solid $black;
        color: $white;
        border-radius: $rounded;
        text-align: center;
        padding: 8px 20px;
        
        @include touch {
            padding: 5px 18px;
        }
        @include handheld {
            padding: 5px 18x;
        }
        &:hover {
            background: transparent;
        }
    }
}

.buttonBlock {
    &.left {
        .button {
            margin: 0;
        }
    }
    &.center {
        .button {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

button {
    @extend .button;
    background: $red;
    color: $white;
    border-radius: 0;
    text-align: center;
    padding: 8px 15px;
        
    @include touch {
        padding: 5px 18px;
    }
    @include handheld {
        padding: 5px 18x;
    }
    &:hover {
        background: $dkRed;
    }
}

.videoPrompt {       
    .videoButton {
        background: $white;
        width: 74px;
        height: 74px;
        border-radius: $round;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend .pulse;
        @extend .transition-all;
        
        @include mobile {
            width: 60px;
            height: 60px;
        }
                    
        .svg-inline--fa {
            color: $red;
            font-size: 1.5em;
            position: relative;
            right: -1px;
            @extend .transition-color;
        }
        &:hover {
            background: $red;
                        
            .svg-inline--fa {
                color: $white;
            }
        }
    }
}