#cta {
    background-color: $iron;
    @extend .backgroundSettings;
    text-align: center;
    padding: 25px;
    position: relative;

    &.hidden {
        display: none;
    }
    
    @include touch {
        padding: 35px 20px;
    }
    
    .pattern {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .container {
        position: relative;
        z-index: 20;
    }
    
    h5 {
        display: inline-block;
        vertical-align: middle;
        margin: 0 30px 0 0;
        color: $white;
        font-size: 2.5em;
        position: relative;
        top: 1px;
        
        @include touch {
            display: block;
            margin: 0 0 20px 0;
            font-size: 2.2em;
        }
        @include handheld {
            font-size: 1.8em;
        }
    }
    button {
        display: inline-block;
        vertical-align: middle;
        
        @include touch {
            display: block;
            margin: 0 auto;
        }
    }
}