#blog {
    .no-results {
        .fa-telescope {
            color: $metal;
        }
    }
    .blogPosts {
        padding: 0 0 60px 0;
    }
    .search_filter {
        padding: 50px 20px 40px 20px;
        margin: 0 auto;
        text-align: center;
        
        @include handheld {
            padding: 30px 15px;
        }
        form {
            margin: 0 auto;
            text-align: center;
            
            .control {
                @include handheld {
                    margin: 0 0 20px 0;
                }
            }
            .field {
                &.is-grouped {
                    @include handheld {
                        display: block!important;
                    }   
                }
            }
            input {
                border: solid 2px $iron;
            }
        }
    }
    .searchPagination {
        border-top: 4px solid $ltGrey;
        color: $black;
        padding: 0;
        font-weight: 700;
        max-width: 100%;
        overflow: hidden;
        
        .paginationPages {
            color: $black;
            .current {
                border-top: solid 4px $red;
                color: $red;
                display: inline-block;
                font-size: 1em;
                font-weight: 700;
                margin-top: -4px;
                padding: 15px;
            }
            a {
                color: $black;
                display: inline-block;
                padding: 15px 0;
            }
        }
        a {
            color: $black;
            display: inline-block;
            font-weight: 700;
            padding: 15px 0;
        }
        .next {
            padding-right: 15px;
        }
        .prev {
            padding-left: 15px;
        }
        
    }
    form {
        input,
        select {
            border-radius: 0;
            font-size: 1.2em;
            font-weight: 700;
            height: 3em;
            padding-left: 25px;
            &::placeholder {
                color: $iron;
            }
        }
        select {
            border: solid 2px $black;
            min-width: 300px;
        }
        .control {
            margin-top: 0;
        }
        .select:not(.is-multiple):not(.is-loading)::after {
            border-color: $red;
            margin-top: 0;
        }
        .field.is-grouped.is-grouped-centered {
            align-items: center;
        }
        .icon {
            height: 100%;
            .svg-inline--fa {
                font-size: 1.1em;
                color: $red;
            }
        }
    }
}