#resources {
    .resourcePosts {
        padding: 100px 0;

        .column {
            padding-top: 0;
            padding-bottom: 0;
        }
        @include small-desktop {
            padding: 80px 0;
        }
        @include touch {
            padding: 60px 0;
        }
    }
}