.networkSummary {
	.videoEmbed {
		width: 100%;
		max-width: 100%;
		margin-top: 50px;
	}
	.sidebar {
		background-color: $black;
		padding: 40px 40px 0 40px;
        border-bottom: 5px solid $red;
        position: relative;
        
        @include touch {
            margin-top: 15px;
        }

		h3 {
			color: $white;
		}
        ul {
            counter-reset: li;
            list-style-type: none;
            padding: 0;
            margin: 30px 0 20px 0;

            li {
                color: $white;
                font-size: 20px;
                font-weight: 400;
                margin: 10px 0;

            }
            li::before {
                content: "▪";
                counter-increment: li;
                padding-right: 5px;
                color: $yellow!important;
            }
        }
        .button {
            margin: 0 auto;
            padding: 12px 20px;
            position: relative;
            top: 28px;
            display: block;
            width: 50%;
            
            @include handheld {
                width: 80%;
            }
        }
	}
}