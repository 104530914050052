/*---------------
  FONTS
---------------*/
$primary: industry, sans-serif;
$body: 'Overpass', sans-serif;
 
/*---------------   
  COLORS  
---------------*/  
$black: #252F38;
$iron: #607380;
$metal: #84939E;
$red: #DB2422;
$dkRed: #B71715;
$yellow: #F7B745;
$dkYellow: #dd971a;
$blue: #00B6D7;
$dkBlue: #00a4c1;
$ltGrey: #EAEDF0;
$offWhite: #F5F5F5;
$white: #FFF;

/*---------------
  MEDIA QUERIES
---------------*/
$xsmobile-width: 380px;
$mobile-width: 480px;
$tablet-width: 769px;
$small-desktop-width: 1024px;
$desktop-width: 1280px;
$full-width: 1440px;

/*---------------
  GENERAL
---------------*/
$transition: .3s;
$shadow: 0px 2px 4px rgba(12, 13, 16, 0.6);
$radius: 0px;
$rounded: 50px;
$round: 100%;
$delay: .3s;