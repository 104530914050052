.dmaImport {
    .dmaCard {
        background: $ltGrey;
        padding: 14px;
        position: relative;
        
        h4 {
            color: $black;
            font-size: 20px;
            display: inline-block;
            margin: 0;
            position: relative;
            top: 2px;
        }
        svg {
            color: $red;
            font-size: 20px;
            position: relative;
            top: 1px;
            float: right;
        }
        .hvrBorder {
            width: 0;
            height: 4px;
            background: $red;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
            @extend .transition-width;
        }
        &:hover {
            .hvrBorder {
                width: 100%;
            }
        }
    }
}